/* You can add global styles to this file, and also import other style files */

@import './assets/styles/themes/themes.scss';

html {
    height: 100%;
    width: 100%;
    font-size: 14px;
}

body,
webcoffee-admin-root {
    width: 100%;
    height: 100%;
    background: rgba(93, 64, 55, 0.1);
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
}

/* Order */
@layer reset, primeng;

/* Reset CSS */
@layer reset {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        // user-select: none;
    }
}


input,
textarea {
    user-select: text;
    -webkit-user-select: text;
}

:focus {
    outline: 0;
}

:root {
    --toastr-success: rgba(70, 176, 70, 0.75);
    --toastr-success-background: #46b046;
    --toastr-success-border: rgba(70, 176, 70, 0.75);
    --toastr-danger: rgba(203, 53, 34, 0.75);
    --toastr-danger-background: #cb3522;
    --toastr-danger-border: rgba(203, 53, 34, 0.75);
    --toastr-info: rgba(76, 142, 255, 0.75);
    --toastr-info-background: #4c8eff;
    --toastr-info-border: rgba(76, 142, 255, 0.75);
    --toastr-warning: rgba(255, 191, 94, 0.75);
    --toastr-warning-background: #ffbf5e;
    --toastr-warning-border: rgba(255, 191, 94, 0.75);
    --toastr-success-text: #d4edda;
    --toastr-danger-text: #f8d7da;
    --toastr-info-text: #d1ecf1;
    --toastr-warning-text: #fff3cd;
}
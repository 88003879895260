mat-form-field.mat-mdc-form-field {
    width: 100%;

    label.mat-mdc-form-field-label {
        font-size: 10px;
        top: 1.45em;
    }

    &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label,
    &.mat-mdc-form-field-can-float .mat-mdc-input-server:focus+.mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
        transform: translateY(-1.25em) scale(0.75);
        width: 133.33333%;
    }
}
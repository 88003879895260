.w-25 {
    width: 25%;
}

.w-50 {
    width: 50%;
}

.w-75 {
    width: 75%;
}

.w-100 {
    width: 100%;
}

.h-10 {
    height: 10vh;
}

.h-20 {
    height: 20vh;
}

.h-30 {
    height: 30vh;
}

.h-40 {
    height: 40vh;
}

.h-50 {
    height: 50vh;
}

.h-60 {
    height: 60vh;
}

.h-70 {
    height: 70vh;
}

.h-80 {
    height: 80vh;
}

.h-90 {
    height: 90vh;
}

.h-100 {
    height: 100vh;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.text-justify {
    text-align: justify !important;
}

.cr-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-space-around {
    justify-content: space-around;
}

.justify-flex-start {
    justify-content: flex-start;
}

.justify-flex-end {
    justify-content: flex-end;
}

.inline-block {
    display: inline-block;
    width: auto;
}

.text-nowrap {
    white-space: nowrap !important;
}

.pre-wrap {
    white-space: pre-wrap !important;
}

.wrap-normal {
    white-space: normal !important;
}

.break-spaces {
    white-space: break-spaces !important;
}

.select-text {
    cursor: text;
    user-select: text;
}

.pointer-cursor {
    cursor: pointer;
}

.no-click-user {
    pointer-events: none;
}

.text-red {
    color: #cb3522;
}

.text-warning {
    color: #be9201;
}

.text-success {
    color: green;
}

.column-border-right {
    border-right: 1px dashed gray;
}

hr.custom-spacer {
    border-color: rgba(125, 125, 125, 0.25);
}

.uppercase {
    text-transform: uppercase;
}

.min-width-250 {
    min-width: 250px;
}

.opacity-50 {
    opacity: 0.5;
}

.line-through {
    text-decoration: line-through;
}

.no-values {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
}

.text-bold {
    font-weight: bold;
}
.mat-mdc-tab-group {
    height: 100%;

    .mat-mdc-tab-body-wrapper {
        flex: 1;

        .mat-mdc-tab-body-content {
            padding: 12px;
        }
    }

    .mat-mdc-tab-header {
        --mdc-secondary-navigation-tab-container-height: 36px;
    }

    .mat-mdc-tab-labels .mat-mdc-tab {
        border-radius: 1rem 1rem 0 0;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 14px;
        transition: all 250ms ease-in-out;
        background: rgba(123, 31, 162, 0.05);

        .mdc-tab__text-label {
            color: rgba(123, 31, 162, 0.85);
        }

        .mdc-tab-indicator {
            .mdc-tab-indicator__content--underline {
                border-top-width: 1px;
                border-color: rgba(123, 31, 162, 0.85);
            }
        }

        .mdc-tab__ripple::before,
        .mat-mdc-tab-ripple {
            border-radius: 1rem 1rem 0 0;
            background: rgba(123, 31, 162, 0.1);
        }

        &.success {
            background: rgba(70, 176, 70, 0.1);
            .mdc-tab__text-label {
                color: rgba(26, 68, 26, 0.85);
            }
            .mdc-tab-indicator {
                .mdc-tab-indicator__content--underline {
                    border-top-width: 1px;
                    border-color: rgba(26, 68, 26, 0.85);
                }
            }
            .mdc-tab__ripple::before,
            .mat-mdc-tab-ripple {
                border-radius: 1rem 1rem 0 0;
                background: rgba(70, 176, 70, 0.1);
            }
        }
        &.warning {
            background: rgba(255, 191, 94, 0.1);
            .mdc-tab__text-label {
                color: rgba(95, 71, 34, 0.85);
            }
            .mdc-tab-indicator {
                .mdc-tab-indicator__content--underline {
                    border-top-width: 1px;
                    border-color: rgba(95, 71, 34, 0.85);
                }
            }
            .mdc-tab__ripple::before,
            .mat-mdc-tab-ripple {
                border-radius: 1rem 1rem 0 0;
                background: rgba(255, 191, 94, 0.1);
            }
        }
        &.error {
            background: rgba(202, 39, 18, 0.1);
            .mdc-tab__text-label {
                color: rgba(76, 16, 8, 0.85);
            }
            .mdc-tab-indicator {
                .mdc-tab-indicator__content--underline {
                    border-top-width: 1px;
                    border-color: rgba(76, 16, 8, 0.85);
                }
            }
            .mdc-tab__ripple::before,
            .mat-mdc-tab-ripple {
                border-radius: 1rem 1rem 0 0;
                background: rgba(202, 39, 18, 0.1);
            }
        }

        &.mdc-tab--active {
            border-radius: 0;
            font-weight: bold;
            background: rgba(123, 31, 162, 0.15);
            .mdc-tab__text-label {
                color: rgba(123, 31, 162, 0.95);
            }
            .mdc-tab-indicator {
                .mdc-tab-indicator__content--underline {
                    border-top-width: 2px;
                    border-color: rgba(123, 31, 162, 0.95);
                }
            }
            .mdc-tab__ripple::before,
            .mat-mdc-tab-ripple {
                border-radius: 0;
                background: rgba(123, 31, 162, 0.25);
            }

            &.success {
                background: rgba(70, 176, 70, 0.25);
                .mdc-tab__text-label {
                    color: rgba(26, 68, 26, 0.95);
                }
                .mdc-tab-indicator {
                    .mdc-tab-indicator__content--underline {
                        border-top-width: 2px;
                        border-color: rgba(26, 68, 26, 0.95);
                    }
                }
                .mdc-tab__ripple::before,
                .mat-mdc-tab-ripple {
                    border-radius: 0;
                    background: rgba(70, 176, 70, 0.25);
                }
            }
            &.warning {
                background: rgba(255, 191, 94, 0.25);
                .mdc-tab__text-label {
                    color: rgba(95, 71, 34, 0.95);
                }
                .mdc-tab-indicator {
                    .mdc-tab-indicator__content--underline {
                        border-top-width: 2px;
                        border-color: rgba(95, 71, 34, 0.95);
                    }
                }
                .mdc-tab__ripple::before,
                .mat-mdc-tab-ripple {
                    border-radius: 0;
                    background: rgba(255, 191, 94, 0.25);
                }
            }
            &.error {
                background: rgba(203, 53, 34, 0.25);
                .mdc-tab__text-label {
                    color: rgba(76, 16, 8, 0.95);
                }
                .mdc-tab-indicator {
                    .mdc-tab-indicator__content--underline {
                        border-top-width: 2px;
                        border-color: rgba(76, 16, 8, 0.95);
                    }
                }
                .mdc-tab__ripple::before,
                .mat-mdc-tab-ripple {
                    border-radius: 0;
                    background: rgba(203, 53, 34, 0.25);
                }
            }
        }
    }
}

.mat-icon {
    &[small] {
        height: 1.4rem;
        width: 1.4rem;
    }
    &[medium] {
        height: 1.8rem;
        width: 1.8rem;
    }

    &[success] {
        color: rgba(70, 176, 70, 0.75);
    }

    &[info] {
        color: rgba(76, 142, 255, 0.75);
    }

    &[warning] {
        color: rgba(255, 191, 94, 0.75);
    }

    &[error] {
        color: rgba(203, 53, 34, 0.75);
    }
}

.mat-mdc-card {
    padding: 0;

    .mat-mdc-card-header {
        align-items: center;
        padding: 6px 12px;

        .mat-mdc-card-header-text {
            margin: 0;
            .mat-mdc-card-title {
                margin: 0;
                font-size: 18px;
            }
        }
    }

    .mat-mdc-card-content {
        padding: 12px;
        margin: 0;
    }

    .mat-mdc-card-actions {
        align-items: center;
        padding: 12px !important;
        margin: 0 !important;
        display: flex;

        &[right] {
            justify-content: flex-end;
        }
        &[left] {
            justify-content: flex-start;
        }
        &[center] {
            justify-content: center;
        }
        &[space-around] {
            justify-content: space-around;
        }
        &[space-between] {
            justify-content: space-between;
        }
    }
}

.mat-mdc-dialog-container {
    h3.mat-dialog-title {
        margin: 0 0 20px;
    }
}

.profile-menu .mat-mdc-menu-content {
    padding: 0;

    .mat-mdc-menu-item {
        min-width: 200px;

        &[small] {
            border-bottom: 1px solid #777;
            font-weight: bold;
            pointer-events: none;
            text-align: center;
        }
    }
}

.no-padding .mat-mdc-menu-content {
    padding: 0;
}

.mat-mdc-menu-item {
    height: 36px;
    line-height: 36px;

    &[small] {
        height: 24px;
        min-height: 24px;
        line-height: 24px;
        font-size: 12px;
    }

    &.active {
        background: rgba(125, 125, 125, 0.15);
        text-decoration: underline;
    }
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 0.5rem -0.3rem rgba(0, 0, 0, 0.2), 0 0.8rem 1rem 0.1rem rgba(0, 0, 0, 0.14), 0 0.3rem 1.4rem 0.2rem rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
    border: dotted 0.3rem #4a148c;
    min-height: 4rem;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: rgba(#4a148c, 0.25);
}

.cdk-drop-list {
    &.empty {
        border: dotted 0.3rem #4a148c;
        min-height: 3rem;
        color: grey;
        display: flex;
        align-items: center;
        padding: 1rem;
    }
}

.mat-mdc-chip.mat-mdc-standard-chip {
    height: 24px;
    font-weight: bold;

    &.success {
        background: var(--toastr-success);
    }
    &.info {
        background: var(--toastr-info);
    }
    &.danger {
        background: var(--toastr-danger);
    }
    &.warning {
        background: var(--toastr-warning);
    }
}

.wc-modal-large {
    width: 80%;
}

.wc-modal-medium {
    width: 50%;
}

.wc-modal-small {
    width: 25%;
}

@media only screen and (max-width: 600px) {
    .wc-modal-large,
    .wc-modal-medium,
    .wc-modal-small {
        width: 95%;
        max-width: 95vw !important;
    }
}

.mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
        .mat-mdc-dialog-content {
            padding: 12px 24px;
        }
    }
}

.p-menuitem.danger-text {
    .p-menuitem-content {
        .p-menuitem-link {

            .p-menuitem-text,
            .p-menuitem-icon {
                color: #cb3522;
            }
        }
    }
}
.mat-mdc-button-base {
    min-height: 36px;
    background: transparent;
    color: white;

    &.mat-mdc-raised-button.mat-unthemed,
    &.mat-mdc-unelevated-button.mat-unthemed {
        background: rgba(125, 125, 125, 0.1);
    }

    &[fix-width] {
        min-width: 100px;
    }

    .mat-mdc-button-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &.mat-mdc-unelevated-button .mat-icon,
    &.mat-mdc-unelevated-button .mat-spinner {
        display: inline-flex;
        align-items: center;
        height: 18px;
        width: 18px;
    }

    .mdc-button__label {
        display: flex;
        align-items: center;
    }

    &[small],
    &.small {
        font-size: 12px;
        height: 28px;
        padding: 0 4px;

        .mat-icon {
            height: 16px;
            width: 16px;
            margin: 0;

            svg {
                height: 16px;
                width: 16px;
            }
        }
    }

    &[large],
    &.large {
        font-size: 18px;
        height: 44px;
        padding: 0 15px;

        .mat-icon {
            height: 24px;
            width: 24px;
            margin: 0;

            svg {
                height: 24px;
                width: 24px;
            }
        }

        &.mat-icon-button {
            padding: 0 1px;
        }
    }

    &.mat-icon-button:hover {
        background: transparent;
    }

    &.mat-mdc-icon-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &[small] {
            height: 28px;
            width: 28px;
            padding: 4px;
        }
    }

    &[neutral] {
        &.mat-mdc-mini-fab.mat-accent {
            --mdc-fab-container-color: unset;
        }
    }
}


.mat-button-toggle-group {
    width: 100%;
    display: flex;
    align-items: center;

    .mat-button-toggle {
        flex: 1;

        .mat-button-toggle-label-content {
            line-height: 36px;
        }

        &.mat-button-toggle-checked {
            background-color: rgba(70, 176, 70, 0.25);
        }
    }
}

.mat-form-field-suffix .ngx-mat-color-toggle .mat-button-base,
.ngx-mat-color-collection .btn-color.mat-button-base {
    min-width: 10px;
    min-height: 10px;
}